import * as React from "react"
import Layout from "../../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'

const heightMax = {
	maxHeight: "601px",
	overflow: "hidden",
}


const qvm = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'single-page whitemain'
    }}
/>
  <Layout pageTitle="qvm">
    <section id="main">
      <div id="worktitle">
        <span>
          <h1>Sweeney Interpreting</h1>
          <span className="highlight"></span>
        </span>
        <span>Bookings Portal App Development</span>
      </div>
    </section>

    <section id="work">
      <div className="work">
          <div className="picture workpicture">
		  	<img src={'/img/works/Sweeney-Interpreting.jpg'} alt="Sweeney Interpreting" />

          </div>

        <div className="seeWork">
          <div className="seeWork__content">
            <div className="icons">
              <img className="picture" src={'/img/single/wordpress-icon.png'} width="139" height="87" alt="Wordpress" />

            </div>
            <a className="button visitwebsite" href="https://sweeneyinterpreting.com.au/" target="_blank"  rel="noreferrer"><span className="seemore"><i>&#xe801;</i></span>Visit Website</a>
          </div>
        </div>

      </div>
    </section>

    <section id="single-content">
      <div className="single-content">
        <p>Sweeney Interpreting provide interpreting services in all areas of life – work, personal, medical, social and public events.</p>
        <p>Tobstar deliver customised bookings portal app development powered by WordPress with Advanced Custom Fields and Gravity Forms plugins to manage bookings.</p>
      </div>

      <div className="project-image">
        <div className="left-col">
          <img className="picture" src={'/img/single/sweeney-desktop.jpg'} alt="Desktop View" />
        </div>
        <div className="right-col" style={heightMax}>
          <img className="picture" src={'/img/single/sweeney-mobile.jpg'} alt="Mobile View" />
      </div>
      </div>
    </section>


    <section id="post-pagination">
      <div className="post-pagination">
        <div className="previous">
          <AniLink cover to="/work/asapd" bg="#00ccd4" direction="right" duration={0.76} className="button project prev">Prev Work</AniLink>
          <p className="project-name">ASAPD</p>
        </div>
        <div className="next">
          <AniLink cover to="/work/sports-excellence-scholarship-funds" bg="#00ccd4" direction="right" duration={0.76} className="button project next">Next Work</AniLink>
          <p className="project-name">Sports Excellence Scholarship Funds</p>
        </div>
      </div>
    </section>
</Layout>

  </>
  )
}

export default qvm


export const Head = () => (
  <>
    <title>QVM Winter Night Market - Tobstar</title>
<meta name="description" content="Sweeney Interpreting provide interpreting services in all areas of life – work, personal, medical, social and public events." />
  </>
)